import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceService } from '../messaging/device.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiURL: any = '';
  mediaURL: any = '';
  ipApiUrl: any = 'https://api.ipify.org?format=json';
  data: any;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private deviceService: DeviceService
  ) {
    this.apiURL =  this.configService.config.apiURL + '/';
    this.mediaURL = this.configService.config.mediaURL;
    this.ipApiUrl = this.configService.config.ipUrl;
  }

  /**
   * Auth Method
   */
  public auth(body?: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }
    const param = JSON.stringify(body);
    const promise = new Promise<void>((resolve) => {
      return this.http.post(this.apiURL + 'auth/employees', param, header).subscribe(async (res: any) => {
        this.data = await res;
        resolve(this.data);
      }, err => {
        resolve(err);
      })
    });
    return promise;
  }

  /**
   * GET Permissions Method
   */
  public checkPermission(permissionId?: any): Observable<any> | any {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    };
    const promise = new Promise<any>((resolve) => {
      return this.http.get(this.apiURL + 'crud/permissions/' + permissionId, header).subscribe(async (res: any) => {
        this.data = await res;
        this.log(this.data);
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }

  /**
   * GET Method
   */
  public get(url?: any): Observable<any> | any {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    };
    const promise = new Promise<any>((resolve) => {
      return this.http.get(this.apiURL + url, header).subscribe(async (res: any) => {
        this.data = await res;
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }

  /**
   * POST Method
   */
  public post(url?: any, body?: any) {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    }
    const param = JSON.stringify(body);
    const promise = new Promise<any>((resolve) => {
      return this.http.post(this.apiURL + url, param, header).subscribe((res: any) => {
        this.data = res;
        this.log(this.data);
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }

  /**
   * Update or PUT Method
   */
  public put(url?: any, body?: any) {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    }
    const param = JSON.stringify(body);
    const promise = new Promise<any>((resolve) => {
      return this.http.put(this.apiURL + url, param, header).subscribe((res: any) => {
        this.data = res;
        this.log(this.data);
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }

  /**
   * Delete Information
   */
  public delete(url?: any, id?: any) {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    }
    const promise = new Promise<any>((resolve) => {
      return this.http.delete(this.apiURL + url + id, header).subscribe((res: any) => {
        this.data = res;
        this.log(this.data);
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }

  /**
   * Get Image Method
   */
  public getImage(url?: any): Promise<any> | null {
    const accessToken = sessionStorage.getItem('access_token');
    return new Promise<any>((resolve) => {
      const header = {
        headers: new HttpHeaders()
          .set('Authorization', `${accessToken}`)
      }
      this.http.get(this.mediaURL + 'download/' + url, header).subscribe(res => {
        resolve(res);
        this.log(res);
      }, err => {
        resolve(err);
      });
    });
  }

  /**
   * File Upload
   */
  public fileUpload(files: File[]) {
    const accessToken = sessionStorage.getItem('access_token');
    const formData = new FormData();

    const header = {
      headers: new HttpHeaders()
        .set('Authorization', `${accessToken}`)
    }

    Array.from(files).forEach(f => formData.append('file', f));
    return this.http.post(this.apiURL + 'file/photo', formData, header);
  }

  /**
   * Log Information
   */
  public log(notes?: any) {
    let accessToken: any = '';
      if (notes.access_token) {
        accessToken = notes.access_token
      } else {
        accessToken = sessionStorage.getItem('access_token');
      }
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `${accessToken}`)
      }
      const param = {
        user_id: sessionStorage.getItem('employee_id'),
        ip_address: this.deviceService.deviceIP,
        url_path: window.location.href,
        notes: JSON.stringify(notes),
        deviceInfo: this.deviceService.deviceUID,
        isDelete: 0
      }
      const body = JSON.stringify(param);

      const promise = new Promise<any>((resolve) => {
        return this.http.post(this.apiURL + 'crud/logs', body, header).subscribe((res: any) => {
          this.data = res;
          resolve(this.data);
        }, err => {
          resolve(err);
        });
      });
      return promise;
  }

  /**
   * GET SOFTWARE SETTINGS METHOD
   */
  public getSoftwareSettings(url?: any): Observable<any> | any {
    const accessToken = sessionStorage.getItem('access_token');
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${accessToken}`)
    };
    const promise = new Promise<any>((resolve) => {
      return this.http.get(this.apiURL + url, header).subscribe(async (res: any) => {
        this.data = await res;
        resolve(this.data);
      }, err => {
        resolve(err);
      });
    });
    return promise;
  }
}
