import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { MemberModel } from '../model/members.model';
import { MemberCheckInModel } from '../model/member-checkin.model';

interface WebSocketMessage {
  type: string;
  userId: string;
  targetUserId?: string;
  content?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private configService: ConfigService = new ConfigService;

  private ws!: WebSocket;
  private messageSubject: Subject<any> = new Subject();
  private reconnectInterval = 5000; // 5 seconds
  private userId!: string;
  private wsUrl: string = this.configService.config.wsURL;
  private shiftStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private allMembers$: BehaviorSubject<MemberModel[]> = new BehaviorSubject<MemberModel[]>([]);
  private allMemberCheckins$: BehaviorSubject<MemberCheckInModel[]> = new BehaviorSubject<MemberCheckInModel[]>([]);

  connect(userId: any) {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }
    
    this.userId = userId;
    this.ws = new WebSocket(this.wsUrl);

    this.ws.onopen = () => {
      this.registerUser(this.userId);
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message && message.type === 'message') {
        this.messageSubject.next(message);
      }

      if (message && message.type === 'members') {
        this.allMembers$.next(message);
      }

      if (message && message.type === 'shiftStatus') {
        this.shiftStatus$.next(message);
      }

      if (message && message.type === 'checkins') {
        this.allMemberCheckins$.next(message);
      }
    };

    this.ws.onclose = () => {
      console.warn('WebSocket connection closed, attempting to reconnect');
      setTimeout(() => this.connect(this.userId), this.reconnectInterval);
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.ws.close();
    };
  }

  private registerUser(userId: string) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      const registerMessage: WebSocketMessage = { type: 'register', userId };
      this.ws.send(JSON.stringify(registerMessage));
    } else {
      console.error('WebSocket is not open');
    }
  }

  sendMessage(message: WebSocketMessage) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open');
    }
  }

  getMessages(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  getShiftStatus(): Observable<boolean> {
    return this.shiftStatus$.asObservable();
  }

  getAllMembers(): Observable<MemberModel[]> {
    return this.allMembers$.asObservable();
  }

  getAllMemberCheckins(): Observable<MemberCheckInModel[]> {
    return this.allMemberCheckins$.asObservable();
  }
}
