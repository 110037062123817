import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigGuard implements CanActivate {

  constructor(private configService: ConfigService, private router: Router) {}

  canActivate(): boolean {
    if (this.configService.isConfigPresent()) {
      return true;
    } else {
      this.router.navigate(['/setup']);
      return false;
    }
  }
}
