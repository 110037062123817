import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageSharingService {
  private imageUrlSource = new BehaviorSubject<string>('');
  currentImageUrl = this.imageUrlSource.asObservable();

  changeImageUrl(imageUrl: string) {
    this.imageUrlSource.next(imageUrl);
  }

  constructor() { }
}
