import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  getCurrentUser(): number {
    return Number(sessionStorage.getItem('employee_id'));
  }
}
