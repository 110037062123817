// clock-in-out.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})

export class ClockInOutService {
  private configservice: ConfigService = new ConfigService;
  private apiUrl = this.configservice.config.apiURL + 'clockInOut';
  accessToken = sessionStorage.getItem('access_token');
  employeeId = Number(sessionStorage.getItem('employee_id'));
  public clockInStatus: boolean = false;

  header = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `${this.accessToken}`)
  };

  constructor(private http: HttpClient) {
  }

  public getClockInStatus(employeeId: number): Observable<{ clockedIn: boolean }> {
    return this.http.get<{ clockedIn: boolean }>(`${this.apiUrl}/status/${employeeId}`, this.header);
  }

  clockIn(employeeId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/clockIn`, { employeeId: employeeId }, this.header);
  }

  clockOut(employeeId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/clockOut`, { employeeId: employeeId }, this.header);
  }
}
