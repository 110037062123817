// clock-in.component.ts
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/api-services/auth.service';
import { ClockInOutService } from 'src/app/shared/services/model-services/clockInOut.service';

@Component({
  selector: 'app-clock-in',
  templateUrl: './clock-in.component.html',
  styleUrls: ['./clock-in.component.scss']
})
export class ClockInComponent implements OnInit {
  clockedIn: boolean = false;
  employeeId: number;

  constructor(
    public clockInOutService: ClockInOutService, 
    private authService: AuthService,
    private toast: ToastrService
  ) {
    this.employeeId = Number(sessionStorage.getItem('employee_id'));
    this.checkClockInStatus();
  }

  ngOnInit(): void {
    this.employeeId = this.authService.getCurrentUser();
    this.checkClockInStatus();
  }

  checkClockInStatus(): void {
    this.clockInOutService.getClockInStatus(this.employeeId).subscribe(status => {
      if (status) {
        this.clockedIn = status.clockedIn;
      } else {
        this.clockedIn = false;
      }
    });
  }

  clockIn(): void {
    this.clockInOutService.clockIn(this.employeeId).subscribe(
      (res) => {
        if (res && res.id) {
          this.clockedIn = true;
          this.toast.success('You have clocked in at ' + moment(res.clockIn).format('lll'));
        }
      },
      error => {
        this.toast.error('There was an error clocking in.');
      }
    );
  }

  clockOut(): void {
    this.clockInOutService.clockOut(this.employeeId).subscribe((res) => {
      if (res.message === 'Updated successfully.') {
        this.clockedIn = false;
        this.toast.success('You have clocked out at ' + moment(res.clockIn).format('lll'));
      }
    },
    error => {
      this.toast.error('There was an error clocking in.');
    });
  }
}
