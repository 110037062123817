<div>
  <webcam
    [height]="320"
    [width]="webcamWidth"
    [trigger]="triggerObservable"
    [switchCamera]="nextWebcamObservable"
    (imageCapture)="handleImage($event)"
    (initError)="handleInitError($event)"
    [class.mirror]="mirrorWebcam"
    [videoOptions]="{ width: webcamWidth, height: 320 }"
    (cameraSwitched)="cameraWasSwitched($event)">
  </webcam>
  <button class="btn btn-primary" (click)="triggerSnapshot()">Capture</button>
  <button class="btn btn-secondary" (click)="toggleMirrorWebcam()">Mirror</button>
  <div *ngIf="webcamImage">
    <img [src]="webcamImage.imageAsDataUrl"/>
  </div>
</div>