import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import Tesseract, { createWorker } from 'tesseract.js';

@Component({
  selector: 'app-id-scanner',
  templateUrl: './id-scanner.component.html',
  styleUrls: ['./id-scanner.component.scss'],
})
export class IdScannerComponent implements OnInit {
  public webcamImage!: WebcamImage;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId?: string;
  public webcamWidth: number = 480;
  public videoOptions: MediaTrackConstraints = {
    width: this.webcamWidth,
    height: 320
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public mirrorWebcam = false; // Add this property
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  private trigger: Subject<void> = new Subject<void>();

  // image = './assets/images/sample/tx-id.png';
  image = './assets/images/sample/new-tx-id.jpeg';

  constructor() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  ngOnInit() {
    this.updateWebcamWidth();
    window.addEventListener('resize', this.updateWebcamWidth.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateWebcamWidth.bind(this));
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public async handleImage(webcamImage: WebcamImage): Promise<void> {
    this.webcamImage = webcamImage;
    console.info('Received webcam image', webcamImage);
    // Process the image to extract text data
    // this.extractTextFromImage(webcamImage.imageAsBase64);
    await this.uploadImage();
  }

  public handleInitError(error: WebcamInitError): void {
    console.warn('Webcam initialization error', error);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  private updateWebcamWidth() {
    this.webcamWidth = window.innerWidth < 768 ? window.innerWidth - 20 : 480;
  }

  // Toggle the mirroring
  public toggleMirrorWebcam(): void {
    this.mirrorWebcam = !this.mirrorWebcam;
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: 'image/png' });
  }

  // Upload Image
  async uploadImage() {
    const imageBlob = this.dataURItoBlob(this.webcamImage.imageAsDataUrl);
    const imageFile = new File([imageBlob], 'webcam-image.png', { type: 'image/png' });
    const files = [imageFile];
    // this.image = imageFile;
    
    //constant
    this.extractTextFromImage();
  }

  private async extractTextFromImage(): Promise<void> {
    try {
      const worker = await createWorker('eng');
      const recognize = await worker.recognize(this.image);
      const extractedText = this.parseText(recognize);
    } catch (error) {
      console.error('Error during text extraction:', error);
    }
  }

  parseText(text: any): any {
    const name = this.extractField(text, '1.');
    const  DOB = this.extractField(text, 'DOB:');
    const adress = this.extractField(text, '8.');
    // const address = this.extractField(text, 'Address:');
    // const dlNumber = this.extractField(text, 'DL No:');
    // const dob = this.extractField(text, 'DOB:');
    return {
      Name: name,
      Address: DOB,
    };
  }

  extractField(text: string, fieldName: string): string {
    const regex = new RegExp(`${fieldName}\\s*(.*)`);
    const match = text.match(regex);
    return match ? match[1].trim() : 'Not Found';
  }

}
