import { Injectable } from '@angular/core';
import { ConfigModel } from '../model/config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config: ConfigModel = {
    authToken: 'anything',
    encryptionKey: 'aisidns8882IOUm2',
    ipUrl: 'https://api.ipify.org?format=json'
  };

  private configKey = 'appConfig';

  constructor() {
    this.initializeConfig();
    this.loadConfig();
  }

  initializeConfig() {
    this.config = {
      authToken: 'anything',
      encryptionKey: 'aisidns8882IOUm2',
      ipUrl: 'https://api.ipify.org?format=json'
    };
  }

  loadConfig() {
    const config = localStorage.getItem(this.configKey);
    if (config) {
      this.config = JSON.parse(config);
    }
    return config ? JSON.parse(config) : {};
  }

  getConfig() {
    return this.config;
  }

  updateConfig(key: string, value: any) {
    const config = this.loadConfig();
    config[key] = value;
    localStorage.setItem(this.configKey, JSON.stringify(config));
  }

  saveConfig(config: any): void {
    config.authToken = 'anything';
    config.encryptionKey = 'aisidns8882IOUm2';
    config.ipUrl = 'https://api.ipify.org?format=json';
    localStorage.setItem(this.configKey, JSON.stringify(config));
  }

  isConfigPresent(): boolean {
    return !!localStorage.getItem(this.configKey);
  }


}
