import { Component, OnInit } from '@angular/core';
import { ImageSharingService } from '../../services/common-services/image-sharing.service';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent  implements OnInit {

  scannedResult: string | null = null;
  availableDevices?: MediaDeviceInfo[];
  selectedDevice?: MediaDeviceInfo;

  formats = [
    BarcodeFormat.QR_CODE, 
    BarcodeFormat.EAN_13, 
    BarcodeFormat.AZTEC, 
    BarcodeFormat.CODABAR, 
    BarcodeFormat.CODE_128, 
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_93,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_8,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.RSS_14,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION
  ]; // Use BarcodeFormat enum

  constructor(private imageSharingService: ImageSharingService,) { }

  ngOnInit() {}

  handleScanSuccess(result: string): void {
    this.scannedResult = result;
    this.imageSharingService.changeImageUrl(result);
  }

  handleCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    if (devices && devices.length > 0) {
      this.selectedDevice = devices[0]; // Select the first device found
    }
  }

  handlePermissionResponse(hasPermission: boolean): void {
    if (!hasPermission) {
      console.error('Camera permission denied');
      // Provide user feedback or alternative actions here
    } else {
      console.log('Camera permission granted');
    }
  }

  handleError(error: any): void {
    console.error('Error:', error);
  }

}
