import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { ImageSharingService } from 'src/app/shared/services/common-services/image-sharing.service';
import { ApiService } from 'src/app/shared/services/api-services/api.service';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit {
  @Output() closeWebcam: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(private imageSharingService: ImageSharingService, private api: ApiService) {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  ngOnInit() {
    this.updateWebcamWidth();
    window.addEventListener('resize', this.updateWebcamWidth.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateWebcamWidth.bind(this));
  }

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId?: string;
  public videoOptions: MediaTrackConstraints = {};
  public errors: WebcamInitError[] = [];
  public webcamImage!: WebcamImage;
  public webcamWidth: number = 480;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public baseImage: any;

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
    this.closeWebcam.emit();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.uploadImage();
    this.imageSharingService.changeImageUrl(webcamImage.imageAsDataUrl);
    this.toggleWebcam(); // Close webcam after capturing image
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  // Upload Image
  async uploadImage() {
    const imageBlob = this.dataURItoBlob(this.webcamImage.imageAsDataUrl);
    const imageFile = new File([imageBlob], 'webcam-image.png', { type: 'image/png' });
    const files = [imageFile];

    this.baseImage = await this.getBaseValue(files);
    this.imageSharingService.changeImageUrl(this.baseImage);
  }

  // Get Base Value
  getBaseValue(files: any) {
    return new Promise((resolve) => {
      let file;
      for (let i = 0; i < files.length; i++) {
        let reader = new FileReader();
        file = files[i];
        let fileName = file.name;
        let getFileType = file.name.split('.');
        reader.onload = (event: any) => { 
        };
        this.api.fileUpload(files).subscribe((res: any) => {
          const fileName = res.name;
          resolve(fileName);
        });
        reader.readAsDataURL(file);
      }
    });
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([intArray], { type: 'image/png' });
  }

  private updateWebcamWidth() {
    this.webcamWidth = window.innerWidth < 768 ? window.innerWidth - 20 : 480;
  }
}
