<div *ngIf="showWebcam">
  <webcam [height]="320" [width]="webcamWidth" [trigger]="triggerObservable" [switchCamera]="nextWebcamObservable" (imageCapture)="handleImage($event)" (initError)="handleInitError($event)" [videoOptions]="{ width: webcamWidth, height: 320 }" (cameraSwitched)="cameraWasSwitched($event)">
  </webcam>
</div>
<div *ngIf="showWebcam">
  <button class="btn btn-primary" (click)="triggerSnapshot()">Take Snapshot</button>
  <button class="btn btn-danger mx-2" (click)="toggleWebcam()">Close Webcam</button>
</div>
<div *ngIf="webcamImage">
  <h3>Captured Image:</h3>
  <img [src]="webcamImage.imageAsDataUrl" />
</div>
