import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Subscription } from 'rxjs';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService implements OnDestroy {

  apiURL: any = '';
  mediaURL: any = '';
  accessToken: any = '';
  headers: any;
  
  private shiftStatusSubscription!: Subscription; // Use non-null assertion operator
  public isShiftsOpen: boolean = false;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private webSocketService: WebsocketService
  ) {
    this.apiURL = configService.config.apiURL;
    this.mediaURL = configService.config.mediaURL;
    this.accessToken = sessionStorage.getItem('access_token');
    this.headers = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `${this.accessToken}`)
    };
    this.isShiftOpen();
    this.subscribeToShiftStatus();
  }

  // Subscribe to Shift Status Updates
  subscribeToShiftStatus() {
    this.shiftStatusSubscription = this.webSocketService.getShiftStatus().subscribe(
      isOpen => {
        this.isShiftsOpen = isOpen;
      },
      error => console.error('Error receiving shift status:', error)
    );
  }

  // Check If Shift is Open
  isShiftOpen(): boolean {
    try {
      const shiftId = sessionStorage.getItem('shift_id');
      if (shiftId && shiftId !== null) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  // Check If Any Shifts Open
  async isAnyShiftOpen(): Promise<any> {
    try {
      const shiftResult: any = await this.http.get(this.apiURL + 'crud/shifts', this.headers).toPromise();
      const data = shiftResult.filter((x: any) => x.isShiftClosed === 0);
      return data && data.length > 0;
    } catch (error) {
      return false;
    }
  }

  // Public Shift Check
  async isOpen(): Promise<boolean> {
    try {
      this.headers = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }
      const response: any = await this.http.get(this.apiURL + 'software-settings/checkOpenShift', this.headers).toPromise();
      return response;
    } catch (error) {
      return false;
    }
  }

  // Public Member Check-In
  async checkIn(item: any): Promise<any> {
    try {
      this.headers = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }

      const param = JSON.stringify(item);

      const response: any = await this.http.post(this.apiURL + 'software-settings/memberCheckIn', param, this.headers).toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  ngOnDestroy() {
    if (this.shiftStatusSubscription) {
      this.shiftStatusSubscription.unsubscribe();
    }
  }
}
