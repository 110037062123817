// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseURL: 'http://172.16.100.50:3000/',
  // apiURL: 'http://172.16.100.50:3000/',
  // mediaURL: 'http://172.16.100.50:3000/file/',
  // wsURL: 'ws://172.16.100.50:3000',

  // baseURL: 'https://dev.roomruler.com/',
  // apiURL: 'https://dev-api.roomruler.com/',
  // mediaURL: 'https://dev-api.roomruler.com/file/',
  // wsURL: 'wss://dev-api.roomruler.com',

  // baseURL: 'https://royalcasino.roomruler.com/',
  // apiURL: 'https://royalcasino-api.roomruler.com/',
  // mediaURL: 'https://royalcasino-api.roomruler.com/file/',
  // wsURL: 'wss://royalcasino-api.roomruler.com',
  
  // authToken: 'anything',
  // encryptionKey: 'aisidns8882IOUm2',
  // ipUrl: 'https://api.ipify.org?format=json',
  versionNo: '1.0.2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
