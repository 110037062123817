import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebsocketService } from './shared/services/websocket.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceService } from './shared/services/messaging/device.service';
import { SharedModule } from './shared/module/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCacheInterceptor } from './shared/interceptor/http-cache.interceptor';
import { NgxPrintModule } from 'ngx-print';
import { ConfigService } from './shared/services/config.service';
import { ConfigGuard } from './shared/guards/config.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    NgbModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    WebcamModule,
    NgxPrintModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    WebsocketService,
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    }, 
    provideToastr(),
    DeviceService,
    DeviceDetectorService,
    ConfigService,
    ConfigGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
